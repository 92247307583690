import React, {useEffect, useState} from "react";
import {Link, withRouter} from 'react-router-dom';
import {connect}  from 'react-redux';
import {setDescription, setSizes, getModels} from '../../library/common/Actions/ordersActions';
import ModalContact from './modelFrames/ModalContact';
import SVG from 'react-inlinesvg';

import {getCurrentModel, setOrder, getModelsСlothes} from '../../library/api/orders';
import close from '../../resources/images/close.svg';

import styles from "./index.module.scss";

function Model(props) {

    const [model, setModel] = useState({});
    const [bigPreview, setBigPreview] = useState('');
    const [grade, setGrade] = useState('');
    const [list, setList] = useState([{
        info: {fio: ''},
        size: '',
        amount: '',
    }])
    const [area, setArea] = useState(-1);
    const [modal, setModal] = useState(false);
    const [mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [coution, setCoution] = useState(false);
    const [loader, setLoader] = useState(false);
    const [school, setSchool] = useState('');
    const [coutionContact, setCoutionContact] = useState(false);

    useEffect(() => {
        function getAllModels(){
            let id = props.match.params.id;
            getCurrentModel(id).then(res => {
                setModel(res.data);
                if(res.data.previews[0].images[0]){
                    setBigPreview(res.data.previews[0].images[0]);
                }
            }).catch(error => console.log(error));

        }
        function getSchool() {
            let schoolId = props.match.params.schoolId;
            getModelsСlothes(schoolId).then(res => {
                debugger
                setSchool(res.data.results);
            }).catch(error => console.log(error));
        }
        getAllModels();
    },[props, props.history]);

    

    function handlerGrade(value, index){
        let newList = list.slice();
        if (value >= 0){
            newList[index].amount = value;
        } else  newList[index].amount = 0;
        setList(newList);
    }

    function changeSize(index, size) {
        setArea(-1);
        let newList = list.slice();
        newList[index].size = size;
        setList(newList);
    }

    function addPupil(){
        let newList = list.slice();
        newList.push({
                        info: {fio: ''},
                        size: '',
                        amount: '',
                    });
        setList(newList);
    }

    function count(){
        let total = 0
        for(let i = 0; i <  list.length; i++){
            total = total + +list[i].amount
        }
       return total
    }

    function price(){
        let kolvo = count();
        let price = kolvo * model.cost
        return price
    }

    function setNewOrder() {
        if(checkContacts()){
        setModal(false);
        setLoader(true);
        let newOrder = {}
        let path = props.history.location.pathname;
        let id = path.substr(path.lastIndexOf("/") + 1);
        let pupils = [];
        for( let i = 0; i < list.length; i++){
           pupils.push({
               model:id,
               info: {fio :list[i].info.fio},
               size: list[i].size.id,
               amount: list[i].amount,
               color: model.colors[0].id,
           })
        }
        newOrder = {
            items: pupils,
            email: mail,
            phone: phone,
            note: grade
            
        }
        setOrder(newOrder).then(res => {
            setModal(false);
            setLoader(false);
            document.location.assign(res.data.redirect_url);
        }).catch(error => console.log(error))
    } else setCoutionContact(true)
    }

    function checkContacts() {
        let valid = true;
        if(!/.+@.+\..+/i.test(mail)){
        // console.log('email')
            valid = false
        }
        if(phone.length < 18){ //18 с учетом символом в маске ввода
        // console.log(phone.length, 'phone.length')
            valid = false
        }
        return valid
    }

    function handlerFio(value, index){
        let newList = list.slice();
        newList[index].info.fio = value;
        setList(newList);
    }

    function chackOrder(){
        let order = true;
        for(let i = 0; i < list.length; i ++){
            if(list[i].amount <= 0 || list[i].size === '' || list[i].info.fio === ''){
                setCoution(true)
                order = false;
                break
            }
        }
        if(grade.length <= 0){
            order = false;
            setCoution(true)
        }
        if(order){
            setModal(true);
        }
    }

        function deleteLine(index){
            let newList = list.slice();
            newList.splice(index,1);
            setList(newList);
        }

    return (
        <div className={styles.grid}>
            <div className={styles.history}>
            <Link to="/" className={styles.history__link}>На главную /&nbsp; </Link>
            <Link to={`/catalog/${props.match.params.schoolId}`} className={styles.history__link}>{props.school.title ? props.school.title : 'Школа'} /&nbsp;</Link>
            <div className={styles.history__palce}>{model.name}</div>
            </div>
            <div className={styles.container}>
              <div className={styles.preview}>
                  <div className={styles.preview__small}>
                      { typeof(model.previews) !== 'undefined' && typeof(model.previews[0]) !== 'undefined'?
                         model.previews[0].images.map((img, index) => <img key={index} 
                                                                           alt="" src={img} 
                                                                           className={styles.preview__small_img +' '+(img === bigPreview ? styles.preview__small_img_active : '')} 
                                                                           onClick={() => setBigPreview(img)}/>)
                        :
                        ""}
                  </div>
                <img className={styles.preview__big} src={bigPreview} alt="" />
                <div className={styles.info}>
                    <div className={styles.info__goods} onClick={() => props.setDescription(true)}>О товаре</div>
                    <div className={styles.info__sizes} onClick={() => props.setSizes(true)}>Таблица размеров</div>
                </div>
              </div>
              <div className={styles.table}>
                  <div className={styles.grade}>
                    <div className={styles.grade__title}>Номер класса</div>
                    <input className={styles.grade__input} 
                            type="text" 
                            value={grade}
                            onChange={(e) => setGrade(e.target.value)}
                            />
                  </div>
                  <div className={styles.titles}>
                    <div className={styles.titles__name}>Фамилия и Имя</div>
                    <div className={styles.titles__size}>Размер</div>
                    <div className={styles.titles__count}>Кол-во</div>
                  </div>
                  <div className={styles.wrap}>
                    {list.map((item, index) =>
                    <div className={styles.line} key={index}>
                        <div className={styles.line__number}>{index+1}</div>
                        <input className={styles.line__name} 
                                type="text"
                                value={item.name}
                                onChange={(e) => handlerFio(e.target.value, index)}/>
                        <div className={styles.line__size}>
                            <div className={styles.size__overlay+ ' '+ (area === index ? styles.size__overlay_active : '')} onClick={() => setArea(-1)}/>
                            <div className={styles.size} onClick={() => setArea(index)}>{item.size.rus_title}</div>
                            <div className={styles.size__area + ' ' + (index === area ? styles.size__area_active : '')}>
                                {model.size_info ? model.size_info.map(size => <div className={styles.size__item} key={size.id} onClick={() => changeSize(index, size)}>{size.rus_title}</div>) : ''}
                            </div>
                        </div>
                        <input className={styles.line__count}
                                type='number'
                                value={item.amount}
                                onChange={(e) => handlerGrade(e.target.value, index)}
                            />
                            {list.length > 1 &&<SVG src={close} alt="" onClick={() => deleteLine(index)} className={styles.close} />}
                    </div>)}
                  </div>
                  <div className={styles.add} onClick={() => addPupil()}>+ Добавить учащегося</div>
              </div>
              <div className={styles.total}>
              <div className={styles.total__count}>
                      Цена: {model.cost} руб
                  </div>
                  <div className={styles.total__count}>
                      Количество: {count()} шт
                  </div>
                  <div className={styles.total__count}>
                      Стоимость: {price()} руб
                  </div>
              </div>
              <div className={styles.btn} onClick={() => chackOrder()}>Оформить заказ</div>
            </div>
            <ModalContact modal={modal} 
                          close={() => setModal(false)} 
                          pay={() => setNewOrder()}
                          setMail={setMail}
                          mail={mail}
                          phone={phone}
                          setPhone={setPhone}
                           />
                           {coution &&
                           <div className={styles.coution}>
                               <div className={styles.coution__title}>Правильно заполните заказ, напишите номер класса, в каждой строке должно быть ФИО, размер и кол-во. Количество не должно быть отрицательным</div>
                               <img className={styles.coution__close} alt="" src={close} onClick={() => setCoution(false)}/>
                           </div>}
                           {coutionContact &&
                           <div className={styles.coution}>
                               <div className={styles.coution__title}>Правильно укажите контакты</div>
                               <img className={styles.coution__close} alt="" src={close} onClick={() => setCoutionContact(false)}/>
                    </div>}
          {loader && <div className={styles.preloader}>
                <div className={styles.loader}/>
            </div>}
        </div>
    )
}

const mapStateToProps = state => ({
    school: state.ordersReducer.school
});

const mapDispatchToProps = {
    setDescription,
    setSizes
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Model));