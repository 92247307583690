import React from "react";
import Modal from 'react-modal';
import SVG from 'react-inlinesvg';
import {connect}  from 'react-redux';
import close from '../../../../resources/images/close.svg';

import styles from "./index.module.scss";

function Agreement(props) {
    return (
        <Modal  isOpen={props.open}
                overlayClassName={styles.overlay}
                className={styles.modal}
                appElement={document.getElementById('root')}
                onRequestClose={() => props.close()}
                >
                    <div className={styles.wrap}>
                <SVG className={styles.close} src={close} alt="" onClick={() => props.close()}/>
                  <div className={styles.title}>Политика в отношении обработки персональных данных</div>
                  <div className={styles.subtitle}>1. Общие положения</div>
                  <div className={styles.paragraf}>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые Бондаренко Тимофеем Евгеньевичем (далее – Оператор).</div>
                  <div className={styles.paragraf}>1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта http://rasasport.ru.</div>
                  <div className={styles.subtitle}>2. Основные понятия, используемые в Политике</div>
                  <div className={styles.paragraf}>2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;</div>
                  <div className={styles.paragraf}>2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</div>
                  <div className={styles.paragraf}>2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу http://rasasport.ru;</div>
                  <div className={styles.paragraf}>2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;</div>
                  <div className={styles.paragraf}>2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</div>
                  <div className={styles.paragraf}>2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;
</div>
                  <div className={styles.paragraf}>2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;
</div>
                  <div className={styles.paragraf}>2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта http://rasasport.ru;
</div>
                  <div className={styles.paragraf}>2.9. Пользователь – любой посетитель веб-сайта http://rasasport.ru;
</div>
                  <div className={styles.paragraf}>2.10. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;
</div>
                  <div className={styles.paragraf}>2.11. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;
</div>
                  <div className={styles.paragraf}>2.12. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;</div>
                  <div className={styles.paragraf}>2.13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.</div>
                  <div className={styles.subtitle}>3. Оператор может обрабатывать следующие персональные данные Пользователя</div>
                  <div className={styles.paragraf}>3.1. Фамилия, имя, отчество;</div>
                  <div className={styles.paragraf}>3.2. Номер телефона;</div>
                  <div className={styles.paragraf}>3.3. Адрес электронной почты;</div>
                  <div className={styles.paragraf}>3.4. Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).</div>
                  <div className={styles.paragraf}>3.5. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</div>
                  <div className={styles.subtitle}>4. Цели обработки персональных данных</div>
                  <div className={styles.paragraf}>4.1. Цель обработки персональных данных Пользователя — заключение, исполнение и прекращение гражданско-правовых договоров; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте http://rasasport.ru; уточнение деталей заказа.</div>
                  <div className={styles.paragraf}>4.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты mail@rasasport.ru с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».</div>
                  <div className={styles.paragraf}>4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</div>
                  <div className={styles.subtitle}>5. Правовые основания обработки персональных данных</div>
                  <div className={styles.paragraf}>5.1. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте http://rasasport.ru. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.</div>
                  <div className={styles.paragraf}>5.2. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).</div>
                  <div className={styles.subtitle}>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</div>
                  <div className={styles.paragraf}>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</div>
                  <div className={styles.paragraf}>6.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</div>
                  <div className={styles.paragraf}>6.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.</div>
                  <div className={styles.paragraf}>6.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора mail@rasasport.ru с пометкой «Актуализация персональных данных».</div>
                  <div className={styles.paragraf}>6.4. Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора mail@rasasport.ru с пометкой «Отзыв согласия на обработку персональных данных».</div>
                  <div className={styles.subtitle}>7. Трансграничная передача персональных данных</div>
                  <div className={styles.paragraf}>7.1. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.</div>
                  <div className={styles.paragraf}>7.2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.</div>
                  <div className={styles.subtitle}>8. Заключительные положения</div>
                  <div className={styles.paragraf}>8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты mail@rasasport.ru.</div>
                  <div className={styles.paragraf}>8.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</div>
                  <div className={styles.paragraf}>8.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу http://rasasport.ru</div>
                  </div>
        </Modal>
    )
}


const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(Agreement);