import * as aT from '../Constants/commonConstants';

const initialState = {
  school: {},
  orders: {},
  contacts: false,
  sizes: false,
  description: false
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case aT.SET_SCHOOL:
      return {
        ...state,
        school: action.payload
      };
      case aT.SET_ORDER_DATA:
        return {
          ...state,
          orders: action.payload,
        };
        case aT.SET_CONTACTS:
          return {
            ...state,
            contacts: action.payload
          };
          case aT.SET_SIZES:
            return {
              ...state,
              sizes: action.payload
            };
            case aT.SET_DESCRIPTION:
              return {
                ...state,
                description: action.payload
              };
    default:
      return state;
  }
};

export default ordersReducer;
