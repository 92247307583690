import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Header from '../library/common/Components/Header';
import Main from '../modules/Main';
import ReportOrder from '../modules/ReportOrder';
import Orders from '../modules/Orders';
import Model from '../modules/Model';
import Contacts from '../library/common/Components/Contacts';
import Description from '../library/common/Components/Description';
import Sizes from '../library/common/Components/Sizes';

class AppRouter extends React.Component {

  render() {
    return(
        <div>
          <Route path="*" component={Header} />
          <Switch>
                <Route exact path='/' component={Main} />
                <Route path='/catalog/:id' component={Orders} />
                <Route path='/model/:schoolId/:id' component={Model} />
                <Route path='/order/:id' component={ReportOrder} />
          </Switch>
          <Contacts />
          <Description />
          <Sizes />
        </div>
      )
}
}

AppRouter.defaultProps = {
  userInfo: {},
};


export default withRouter(
 
  (AppRouter),
);