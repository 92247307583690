import Axios from 'axios';
import {errorHandler} from '../common/Actions/errorHendler';

const axiosInstance = Axios.create({
  headers: {'Content-Type': 'application/json'},
});

axiosInstance.interceptors.response.use(
  config => config,
  error => {
    errorHandler(error);
    return Promise.reject(error);
  },
);

export default axiosInstance;
