import * as aT from '../Constants/commonConstants';


export const setSchool = (data) => dispatch => {
    dispatch({
        type: aT.SET_SCHOOL,
        payload: data,
    })
}


export const setOrderData = (data) => dispatch => {
    dispatch({
        type: aT.SET_ORDER_DATA,
        payload: data,
    })
}

export const setContacts = (data) => dispatch => {
    dispatch({
        type: aT.SET_CONTACTS,
        payload: data,
    })
}

export const setSizes = (data) => dispatch => {
    dispatch({
        type: aT.SET_SIZES,
        payload: data,
    })
}

export const setDescription = (data) => dispatch => {
    dispatch({
        type: aT.SET_DESCRIPTION,
        payload: data,
    })
}