import React from "react";
import { YMaps, Map, Placemark } from 'react-yandex-maps';

import styles from "./index.module.scss";

function MapContacts(props) {
  return(
    <YMaps>
    <Map defaultState={{ center: [47.2385600566353,39.61099036170565], zoom: 13}} className={styles.map}>
      <Placemark defaultGeometry={[47.2385600566353,39.61099036170565]} />
    </Map>
  </YMaps>
  )}

export default MapContacts;