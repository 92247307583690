let API_BASE_URL;
let url = window.location.href;

if(url.indexOf("http://localhost:3000") !== -1){
  API_BASE_URL = "http://localhost:3000";
 } 
 else if (url.indexOf("http://rasasport.ru") !== -1){
  API_BASE_URL = "http://rasasport.ru";
 } else if (url.indexOf("https://rasasport.ru") !== -1){
  API_BASE_URL = "https://rasasport.ru";
 }
 else {
  API_BASE_URL = "http://" + window.location.host;
 }

 export default {
  API_BASE_URL
};