import axios from './axios';
import config from '../../main/config';

const URL = `${config.API_BASE_URL}/api/v1/`;

export const getAllRegions = () => axios.get(`${URL}institution/city/`);

export const getAllSchoolForRegion = (id) => axios.get(`${URL}institution/institution/`);

export const getModelsСlothes = (id) => axios.get(`${URL}catalog/model/?institution=${id}`);

export const getCurrentModel = (id) => axios.get(`${URL}catalog/model/${id}/`);

export const setOrder = (data) => axios.post(`${URL}orders/order/`, data);

export const showStatusOrder = (id) => axios.get(`${URL}orders/order/${id}/by_personal_code/`);