import React from "react";
import Modal from 'react-modal';
import SVG from 'react-inlinesvg';
import {connect}  from 'react-redux';
import {setSizes} from '../../Actions/ordersActions';

import size from '../../../../resources/images/size.jpg';
import close from '../../../../resources/images/close.svg';

import styles from "./index.module.scss";

function Sizes(props) {
    return (
        <Modal  isOpen={props.sizes}
                overlayClassName={styles.overlay}
                className={styles.modal}
                appElement={document.getElementById('root')}
                onRequestClose={() => props.setSizes(false)}
                >
        <SVG className={styles.close} src={close} alt="" onClick={() => props.setSizes(false)}/>
        <div className={styles.title}>Таблица размеров</div>
        <img src={size} alt="" className={styles.img} />
        <div className={styles.wrap}>
            <div className={styles.item}>Размер</div>
            <div className={styles.item}>Ширина(А) см</div>
            <div className={styles.item}>Длинна(B) см</div>
        </div>
        <div className={styles.table}>
        <div className={styles.wrap}>
            <div className={styles.item}>92</div>
            <div className={styles.item}>29</div>
            <div className={styles.item}>39</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>98</div>
            <div className={styles.item}>30</div>
            <div className={styles.item}>41</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>104</div>
            <div className={styles.item}>31</div>
            <div className={styles.item}>43</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>110</div>
            <div className={styles.item}>32</div>
            <div className={styles.item}>46</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>116</div>
            <div className={styles.item}>33</div>
            <div className={styles.item}>47</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>122</div>
            <div className={styles.item}>34</div>
            <div className={styles.item}>49</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>128</div>
            <div className={styles.item}>38</div>
            <div className={styles.item}>49</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>134</div>
            <div className={styles.item}>40</div>
            <div className={styles.item}>50</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>140</div>
            <div className={styles.item}>42</div>
            <div className={styles.item}>52</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>146</div>
            <div className={styles.item}>44</div>
            <div className={styles.item}>54</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>152</div>
            <div className={styles.item}>46</div>
            <div className={styles.item}>58</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>158</div>
            <div className={styles.item}>48</div>
            <div className={styles.item}>60</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>164</div>
            <div className={styles.item}>49</div>
            <div className={styles.item}>63</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>XS</div>
            <div className={styles.item}>47</div>
            <div className={styles.item}>69</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>S</div>
            <div className={styles.item}>49</div>
            <div className={styles.item}>70</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>M</div>
            <div className={styles.item}>51</div>
            <div className={styles.item}>71</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>L</div>
            <div className={styles.item}>53</div>
            <div className={styles.item}>72</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>XL</div>
            <div className={styles.item}>55</div>
            <div className={styles.item}>74</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>2XL</div>
            <div className={styles.item}>57</div>
            <div className={styles.item}>76</div>
        </div>
        <div className={styles.wrap}>
            <div className={styles.item}>3XL</div>
            <div className={styles.item}>59</div>
            <div className={styles.item}>76</div>
        </div>
        </div>
        </Modal>
    )
}

const mapStateToProps = state => ({
    sizes: state.ordersReducer.sizes
});


const mapDispatchToProps = {
    setSizes
};

export default connect(mapStateToProps, mapDispatchToProps)(Sizes);