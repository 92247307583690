import { combineReducers } from 'redux';

import OrdersReducer from '../library/common/Reducers/ordersReducer';

export default combine();

export function combine() {
    return combineReducers({
      ordersReducer: OrdersReducer,
    });
  }
  