import React from "react";
import Modal from 'react-modal';
import SVG from 'react-inlinesvg';

import close from '../../../../resources/images/close.svg';
import visa from '../../../../resources/images/visa.jpg';

import styles from "./index.module.scss";

function UsersAgree(props) {
    return (
        <Modal  isOpen={props.open}
                overlayClassName={styles.overlay}
                className={styles.modal}
                appElement={document.getElementById('root')}
                onRequestClose={() => props.close()}
                >
                    <div className={styles.wrap}>
                <SVG className={styles.close} src={close} alt="" onClick={() => props.close()}/>
                  <div className={styles.title}>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</div>
                  <div className={styles.city}>
                      <div className={styles.item}>г Ростов-на-Дону</div>
                      <div className={styles.item}>«15» сентября 2020 г</div>
                  </div>
                  <div className={styles.subtitle}>1. ОБЩИЕ ПОЛОЖЕНИЯ</div>
                  <div className={styles.paragraf}>1.1.	Настоящее Пользовательское соглашение (далее – Соглашение) относится к сайту Интернет-ресурса «rasasport», расположенному по адресу https://rasasport.ru </div>
                  <div className={styles.paragraf}>1.2.	Сайт Интернет-ресурса «rasasport» (далее – Сайт) является собственностью ИП Талецкий О.В</div>
                  <div className={styles.paragraf}>1.3.	Настоящее Соглашение регулирует отношения между Администрацией сайта Интернет-ресурса «rasasport» (далее – Администрация сайта) и Пользователем данного Сайта.</div>
                  <div className={styles.paragraf}>1.4. Администрация сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.</div>
                  <div className={styles.paragraf}>1.5. Продолжение использования Сайта Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.</div>
                  <div className={styles.paragraf}>1.6. Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.</div>
                  <div className={styles.subtitle}>2.	ОПРЕДЕЛЕНИЯ ТЕРМИНОВ</div>
                  <div className={styles.paragraf}>2.1.	Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:</div>
                  <div className={styles.paragraf}>2.1.1 «rasasport» – Интернет-ресурс, расположенный на доменном имени https://rasasport.ru, осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов.</div>
                  <div className={styles.paragraf}>2.1.2. Интернет-ресурс – сайт, содержащий информацию о Товарах, Продавце, позволяющий осуществить выбор, заказ и (или) приобретение Товара. </div>
                  <div className={styles.paragraf}>2.1.3. Администрация сайта Интернет-ресурса – уполномоченные сотрудники на управления Сайтом, действующие от имени ИП Талецкий О.В.</div>
                  <div className={styles.paragraf}>2.1.4. Пользователь сайта Интернет-ресурса (далее ‑ Пользователь) – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.</div>
                  <div className={styles.paragraf}>2.1.5. Содержание сайта Интернет-ресурса (далее – Содержание) - охраняемые результаты интеллектуальной деятельности, включая тексты, аннотации, статьи, иллюстрации, обложки, графические, текстовые, фотографические, производные, составные и иные произведения, пользовательские интерфейсы, визуальные интерфейсы, названия товарных знаков, логотипы, программы для ЭВМ, базы данных, а также дизайн, структура, выбор, координация, внешний вид, общий стиль и расположение данного Содержания, входящего в состав Сайта и другие объекты интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на сайте Интернет-ресурса.</div>
                  <div className={styles.subtitle}>3.	ПРЕДМЕТ СОГЛАШЕНИЯ</div>
                  <div className={styles.paragraf}>3.1. Предметом настоящего Соглашения является предоставление Пользователю Интернет-ресурса доступа к содержащимся на Сайте Товарам и оказываемым услугам.</div>
                  <div className={styles.paragraf}>3.1.1. Интернет-ресурс предоставляет Пользователю следующие виды услуг (сервисов):<br/>
•	доступ к информации о Товаре и к информации о приобретении Товара на добровольной основе;<br/>
•	иные виды услуг (сервисов), реализуемые на страницах Интернет-ресурса, включая платные услуги (сервисы).
</div>
                  <div className={styles.paragraf}>3.1.2. Под действие настоящего Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги (сервисы) Интернет-ресурса, а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные услуги (сервисы) Интернет-ресурса.</div>
                  <div className={styles.paragraf}>3.2. Доступ к Интернет-ресурсу предоставляется на добровольной основе.</div>
                  <div className={styles.paragraf}>3.3. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту Пользователь считается присоединившимся к настоящему Соглашению. </div>
                  <div className={styles.paragraf}>3.4. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации</div>
                  <div className={styles.subtitle}>4.	ПРАВА И ОБЯЗАННОСТИ СТОРОН</div>
                  <div className={styles.paragraf2}>4.1. Администрация сайта вправе:</div>
                  <div className={styles.paragraf}>4.1.1. Изменять правила пользования Сайтом, а также изменять содержание данного Сайта. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.</div>
                  <div className={styles.paragraf}>4.1.2. Ограничить доступ к Сайту в случае нарушения Пользователем условий настоящего Соглашения.</div>
                  <div className={styles.paragraf2}>4.2. Пользователь вправе: </div>
                  <div className={styles.paragraf}>4.2.1. Получить доступ к использованию Сайта.</div>
                  <div className={styles.paragraf}>4.2.2. Пользоваться всеми имеющимися на Сайте услугами, а также приобретать любые Товары, предлагаемые на Сайте.</div>
                  <div className={styles.paragraf}>4.2.3. Задавать любые вопросы, относящиеся к услугам Интернет-ресурса по реквизитам, которые находятся в разделе Сайта «rasasport».</div>
                  <div className={styles.paragraf}>4.2.4. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных законодательством Российской Федерации.</div>
                  <div className={styles.paragraf2}>4.3. Пользователь Сайта обязуется:</div>
                  <div className={styles.paragraf}>4.3.1. Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет непосредственное отношение к предоставляемым услугам данного Сайта.</div>
                  <div className={styles.paragraf}>4.3.2. Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании Сайта.</div>
                  <div className={styles.paragraf}>4.3.3. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта.</div>
                  <div className={styles.paragraf}>4.3.4. Не распространять с использованием Сайта любую конфиденциальную и охраняемую законодательством Российской Федерации информацию о физических либо юридических лицах.</div>
                  <div className={styles.paragraf}>4.3.5. Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой законодательством Российской Федерации информации.</div>
                  <div className={styles.paragraf}>4.3.6. Не использовать Сайт для распространения информации рекламного характера, иначе как с согласия Администрации сайта.</div>
                  <div className={styles.paragraf2}>4.4. Пользователю запрещается:</div>
                  <div className={styles.paragraf}>4.4.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания Сайта данного Интернет-ресурса;</div>
                  <div className={styles.paragraf}>4.4.2. Нарушать надлежащее функционирование Сайта;</div>
                  <div className={styles.paragraf}>4.4.3. Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой информации, документов или материалов любыми средствами, которые специально не представлены сервисами данного Сайта;</div>
                  <div className={styles.paragraf}>4.4.4. Несанкционированный доступ к функциям Сайта, любым другим системам или сетям, относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте;</div>
                  <div className={styles.paragraf}>4.4.5. Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к Сайту. </div>
                  <div className={styles.paragraf}>4.4.6. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом Пользователе Сайта.</div>
                  <div className={styles.paragraf}>4.4.7. Использовать Сайт и его Содержание в любых целях, запрещенных законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права интернет-ресурса или других лиц.</div>
                  <div className={styles.subtitle}>5.	ИСПОЛЬЗОВАНИЕ САЙТА ИНТЕРНЕТ-РЕСУРСА</div>
                  <div className={styles.paragraf}>5.1. Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией сайта.</div>
                  <div className={styles.paragraf}>5.2. Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или распространено любым способом, а также размещено в глобальной сети «Интернет» без предварительного письменного согласия Администрации сайта.</div>
                  <div className={styles.paragraf}>5.3. Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими правами, связанными с интеллектуальной собственностью, и законодательством о недобросовестной конкуренции.</div>
                  <div className={styles.paragraf}>5.4. При использовании некоторых сервисов сайта, может потребоваться создание учётной записи Пользователя.</div>
                  <div className={styles.paragraf}>5.5. Пользователь несет персональную ответственность за сохранение конфиденциальности информации учётной записи, включая пароль, а также за всю без исключения деятельность, которая ведётся от имени Пользователя учётной записи. </div>
                  <div className={styles.paragraf}>5.6. Пользователь должен незамедлительно уведомить Администрацию сайта о несанкционированном использовании его учётной записи или пароля или любом другом нарушении системы безопасности. </div>
                  <div className={styles.paragraf}>5.7. Настоящее Соглашение распространяет свое действия на все дополнительные положения и условия о покупке Товара и оказании услуг, предоставляемых на Сайте. </div>
                  <div className={styles.paragraf}>5.8. Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего Соглашения.</div>
                  <div className={styles.paragraf}>5.9. Администрация сайта имеет право в любое время без уведомления Пользователя вносить изменения в перечень Товаров и услуг, предлагаемых на Сайте, и (или) в цены, применимые к таким Товарам по их реализации и (или) оказываемым услугам Интернет-ресурсом.</div>
                  <div className={styles.paragraf}>5.10. Документы, указанные в пунктах 5.10.1 - 5.10.4 настоящего Соглашения регулируют в соответствующей части и распространяют свое действие на использование Пользователем Сайта. </div>
                  <div className={styles.subtitle}>6.	ОТВЕТСТВЕННОСТЬ</div>
                  <div className={styles.paragraf}>6.1. Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям другого Пользователя, Администрацией сайта не возмещаются.</div>
                  <div className={styles.paragraf}>6.2. Администрация сайта не несет ответственности за:</div>
                  <div className={styles.paragraf}>6.2.1. Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах.</div>
                  <div className={styles.paragraf}>6.2.2. Действия систем переводов, банков, платежных систем и за задержки связанные с их работой.</div>
                  <div className={styles.paragraf}>6.2.3. Надлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых технических средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими средствами. </div>
                  <div className={styles.paragraf}>6.2.4. Содержание (контент) информации размещенной на Сайте. </div>
                  <div className={styles.paragraf}>6.3. Пользователь самостоятельно несет ответственность за интерпретацию и использование контента (информации), размещенного на Сайте.</div>
                  <div className={styles.subtitle}>7.	НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</div>
                  <div className={styles.paragraf}>7.1. Администрация сайта вправе раскрыть любую собранную о Пользователе данного Сайта информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Сайта либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться в права Администрации сайта или в права других Пользователей Сайта.</div>
                  <div className={styles.paragraf}>7.2. Администрация сайта имеет право раскрыть любую информацию о Пользователе, которую посчитает необходимой для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий настоящего Соглашения, защиты прав или безопасности название организации, Пользователей.</div>
                  <div className={styles.paragraf}>7.3. Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует или разрешает такое раскрытие.</div>
                  <div className={styles.paragraf}>7.4. Администрация сайта вправе без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине технической неполадки или проблемы.</div>
                  <div className={styles.paragraf}>7.5. Администрация сайта не несет ответственности перед Пользователем или третьими лицами за прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного документа, содержащего условия пользования Сайтом.</div>
                  <div className={styles.subtitle}>8.	РАЗРЕШЕНИЕ СПОРОВ</div>
                  <div className={styles.paragraf}>8.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора).</div>
                  <div className={styles.paragraf}>8.2. Получатель претензии в течение 30 (тридцати) календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</div>
                  <div className={styles.paragraf}>8.3. При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской Федерации.</div>
                  <div className={styles.paragraf}>8.4. Любой иск в отношении условий использования Сайта должен быть предъявлен в течение срока после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в соответствии с законодательством материалы Сайта. При нарушении условий данного пункта любой иск или основания для иска погашаются исковой давностью.</div>
                 
                  <img src={visa} alt="" className={styles.visa}/>
                  <div className={styles.paragraf}>Способ оплаты</div>
                  <div className={styles.paragraf}>
Для оплаты покупки Вы будете перенаправлены на платежный шлюз ПАО «Сбербанк России» для ввода реквизитов Вашей карты. Пожалуйста, приготовьте Вашу пластиковую карту заранее. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL.
В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa или MasterCard Secure Code для проведения платежа также может потребоваться ввод специального пароля. Способы и возможность получения паролей для совершения интернет-платежей Вы можете уточнить в банке, выпустившем карту.
Настоящий сайт поддерживает 258-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО «Сбербанк России». Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем Visa Int. и MasterCard Europe Sprl.
</div>
<div className={styles.paragraf}>Обновлено «15» сентября 2020 г.</div>
                   </div>
        </Modal>
    )
}


export default UsersAgree;