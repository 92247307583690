import React, {useEffect, useState} from "react";
import {Link, withRouter} from 'react-router-dom';
import {connect}  from 'react-redux';

import {getModelsСlothes, getAllSchoolForRegion} from '../../library/api/orders';

import styles from "./index.module.scss";

function Orders(props) {

    const [models, setModels] = useState([]);
    const [school, setSchool] = useState('');
    

    function showModel(id){
        props.history.push(`/model/${props.match.params.id}/${id}`);
    }

    useEffect(() => {
        function getAllModels(){
            let id = props.match.params.id
            getModelsСlothes(id).then(res => {
                setModels(res.data.results);
            }).catch(error => console.log(error));
        }
        getAllModels();
    },[props]);

    useEffect(() => {
        function getDataSchools() {
            getAllSchoolForRegion().then(res=> {
                if(res.data.results){
                    let allSchools = res.data.results;
                    let id = props.match.params.id;
                    let schools = allSchools.find(item => item.id == id);
                    setSchool(schools.note)
                 }
            }).catch(error => console.log(error));
        }
        getDataSchools();
    },[props]);

    return (
        <div className={styles.grid}>
            <div className={styles.history}>
            <Link to="/" className={styles.history__link}>На главную / &nbsp; </Link>
                <div className={styles.history__palce}> {props.school.title ? props.school.title : 'Школа'}</div>
            </div>
            <div className={styles.catalog}>
               { models.map(model => 
               <div className={styles.wrap}>
                    <img key={model.id} 
                    src={model.preview} 
                    className={styles.model} 
                    alt="" 
                    onClick={() => showModel(model.id)}
                    />
                    <div className={styles.price}>{model.cost} руб</div>
                    </div>)}
            </div>
            {school &&
            <div className={styles.attention}>
                {school}
            </div>}
        </div>
    )
}

const mapStateToProps = state => ({
    school: state.ordersReducer.school
});


export default withRouter(connect(mapStateToProps)(Orders));