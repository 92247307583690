import React from "react";
import {withRouter} from 'react-router-dom';
import {connect}  from 'react-redux';
import {setContacts} from '../../Actions/ordersActions';

import styles from "./index.module.scss";

import logo from '../../../../resources/images/logo.svg';

function Header(props) {

    function gotoMain(){
        props.history.push('/');
    }
    return (
        <div className={styles.grid+ ' '+ (props.location.pathname === '/'? '':styles.grid__white)}>
            <img className={styles.logo} src={logo} alt="" onClick={() => gotoMain()}/>
            <div className={styles.title}>ОНЛАЙН-ЗАКАЗ УТВЕРЖДЁННЫХ МОДЕЛЕЙ</div>
            <div className={styles.contacts} onClick={() => props.setContacts(true)}>Контакты</div>
        </div>
    )
}

const mapDispatchToProps = {
    setContacts
};


export default withRouter(connect(null, mapDispatchToProps)(Header));