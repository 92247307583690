import React from "react";
import Modal from 'react-modal';
import {connect}  from 'react-redux';
import {setContacts} from '../../Actions/ordersActions';
import SVG from 'react-inlinesvg';
import MapContacts from '../Map';

import mail from '../../../../resources/images/mail.svg';
import location from '../../../../resources/images/location.svg';
import phone from '../../../../resources/images/phone.svg';
import close from '../../../../resources/images/close.svg';

import styles from "./index.module.scss";

function Contacts(props) {
    return (
        <Modal  isOpen={props.contacts}
                overlayClassName={styles.overlay}
                className={styles.modal}
                appElement={document.getElementById('root')}
                onRequestClose={() => props.setContacts(false)}
                >
                     <SVG className={styles.close} src={close} alt="" onClick={() => props.setContacts(false)}/>
            <div className={styles.wrap}>
                <div className={styles.item}>
                    <SVG src={phone} alt="" />
                    <div className={styles.title}>Телефон</div>
                    <div className={styles.value}>+ 7 (928) 226-53-81</div>
                </div>
                <div className={styles.item}>
                    <SVG src={mail} alt="" />
                    <div className={styles.title}>Почта</div>
                    <div className={styles.value}>mail@rasasport.ru</div>
                </div>
                <div className={styles.item}>
                    <SVG src={location} alt="" />
                    <div className={styles.title}>Адрес</div>
                    <div className={styles.value}>Ростов-на-Дону,<br/> Доватора 142А/37 оф. 50</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.value}>ИП Талецкий О. В.</div>
                    <div className={styles.value}>ОГРНИП 308616209100011</div>
                </div>
            </div>
            <div className={styles.forMap}>
                <MapContacts />
            </div>
        </Modal>
    )
}

const mapStateToProps = state => ({
    contacts: state.ordersReducer.contacts
});


const mapDispatchToProps = {
    setContacts
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);