import React, {useState, useEffect} from "react";
import {showStatusOrder} from '../../library/api/orders';

import styles from "./index.module.scss";

function ReportOrder(props) {

    const[info, setInfo] = useState({});
    const[orderId, setOrderId] = useState('');

    useEffect(() => {
      

    showOrderInfo();
    },[props])

    function showOrderInfo(){
        let path = props.history.location.pathname;
        let id = path.split(/[\\\/]/);
        setOrderId(id[2]);
        showStatusOrder(id[2]).then(res => {
            setInfo(res.data)
        }).catch(error => console.log(error));
    };

    function showStatus(){
        if(info.status === 'unpaid'){
            showOrderInfo();
            return 'Неоплачен'
        } else if(info.status === 'paid'){
            return 'Оплачен'
        } else if(info.status === 'processin'){
            return 'В работе'
        } else if(info.status === 'ready'){
            return 'Готов'
        } else if(info.status ==='cancel'){
            return 'Отменен'
        } 
    }
    return (
        <div className={styles.grid}>
            <div className={styles.title}>Заказ № {info.id}</div>
            <div className={styles.container}>
                <div className={styles.container__preview}>
                    <div className={styles.con__title}>Контактные данные заказчика</div>
                    <div className={styles.wrap}>
                        <div className={styles.wrap__title}>Класс</div>
                        <div className={styles.wrap__value}>{info.note}</div>
                    </div>
                    <div className={styles.wrap}>
                        <div className={styles.wrap__title}>Телефон</div>
                        <div className={styles.wrap__value}>{info.phone}</div>
                    </div>
                    <div className={styles.wrap}>
                        <div className={styles.wrap__title}>Почта</div>
                        <div className={styles.wrap__value}>{info.email}</div>
                    </div>
                    <div className={styles.wrap}>
                        <div className={styles.wrap__title}>Цвет</div>
                        <div className={styles.wrap__value}>{typeof info.items !== 'undefined' && info.items[0].color.title}</div>
                    </div>
                </div>
                <div className={styles.table}>
                    <div className={styles.table__head}>
                        <div className={styles.table__name}>Фамилия Имя</div>
                        <div className={styles.table__size}>Размер</div>
                        <div className={styles.table__amount}>Кол-во</div>
                    </div>
                    {   typeof info.items !== 'undefined' &&
                        info.items.map((pupil, index) =>
                        <div className={styles.line} key={index}>
                        <div className={styles.line__number}>{index+1}</div>
                        <div className={styles.line__name}>{pupil.info ? pupil.info.fio : ''}</div>
                        <div className={styles.line__size}>{pupil.size.rus_title}</div>
                        <div className={styles.line__amount}>{pupil.amount}</div>
                    </div>)}
                    <div className={styles.total}>
                        <div className={styles.total__amount}>В заказе: {typeof info.items !== 'undefined' && info.items.length} шт</div>
                        <div className={styles.total__price}>Cумма: {info.total_price} руб</div>
                    </div>
                </div>
                
            </div>
            <div className={styles.status}>Статус заказа: {showStatus()}</div>
        </div>
    )
}

export default ReportOrder;