import React, {useState, useEffect} from "react";
import {withRouter} from 'react-router-dom';
import Modal from 'react-modal';
import SVG from 'react-inlinesvg';
import {getAllRegions, getAllSchoolForRegion} from '../../library/api/orders';
import {setSchool} from '../../library/common/Actions/ordersActions';
import {connect}  from 'react-redux';
import Agreement from "./mainFrames/Agreement";
import UsersAgree from "./mainFrames/UsersAgree";

import styles from "./index.module.scss";

import closeImg from '../../resources/images/close.svg';


function Main(props) {

    const [regions, setRegions] = useState([]);
    const [openArea, setOpenArea] = useState(false);
    const [choosenRegion, setChoosenRegion] = useState('');
    const [schools, setSchools] = useState([]);
    const [openList, setOpenList] = useState(false);
    const [choosenSchool, setChoosenSchool] = useState('');
    const [showBtn, setShowBtn] = useState(false);
    const [modal, setModal] = useState(true);
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState(false);

    useEffect(() => getRegions(), []);

    function getRegions(){
        getAllRegions().then(res => {
            setRegions(res.data);
        }).catch(error => console.log(error));
    }

    function chooseRegion(area) {
        setChoosenRegion(area.title)
        setOpenArea(false)
        getAllSchoolForRegion().then(res=> {
            setSchools(res.data.results);
        }).catch(error => console.log(error));
    }

    function chooseSchool(school){
        setChoosenSchool(school);
        setShowBtn(true);
        setOpenList(false);
    }

    function goToModels(){
        props.setSchool(choosenSchool);
        props.history.push(`/catalog/`+choosenSchool.id);
    }

    return (
        <div className={styles.grid}>
            <div className={styles.content}>
               <div className={styles.city}>
                   <div className={styles.title}>Выберите Город</div>
                   <div className={styles.city__overlay + " " + (openArea ? styles.city__overlay_active : '')} onClick={() => setOpenArea(false)}/>
                   <div className={styles.city__wrap}>
                        <div className={styles.city__title} onClick={() => setOpenArea(true)}>
                                {choosenRegion}
                        </div>
                        <div className={styles.city__area + " " + (openArea ? styles.city__area_open : '')}>
                                {regions.map(area => <div key={area.id} className={styles.city__item} onClick={() => chooseRegion(area)}>{area.title}</div>)} 
                        </div>
                   </div>
               </div>
               {
                   schools.length !== 0 ?
                   <div className={styles.school}>
                   <div className={styles.title}>Выберите учебное заведение</div>
                   <div className={styles.school__overlay + " " + (openList ? styles.school__overlay_active : '')} onClick={() => setOpenList(false)}/>
                   <div className={styles.school__wrap}>
                       <div className={styles.school__title} onClick={() => setOpenList(true)}>
                            {choosenSchool.title}
                        </div>
                    <div className={styles.school__area + " " + (openList ? styles.school__area_open : '')}>
                            {schools.map(school => <div key={school.id} className={styles.school__item} onClick={() => chooseSchool(school)}>{school.title}</div>)} 
                    </div>
                   </div>
               </div>
               :
               ''
               }
               {
                   showBtn ? 
                   <div className={styles.btn} onClick={() => goToModels()}>Далее</div>
                   :
                   ''
               }
               
           </div>
           {/* <Modal isOpen={modal}
                overlayClassName={styles.overlay}
                className={styles.modal}
                appElement={document.getElementById('root')}
                onRequestClose={() => setModal(false)}
                >
                    <SVG className={styles.close} alt="" src={closeImg} onClick={() => setModal(false)} />
                  <div className={styles.modal__title}>Добрый день!</div>
<div className={styles.modal__subtitle}>Если Вы видите это сообщение, то в данный момент ведётся настройка платёжной системы.</div>

<div className={styles.modal__title}>Приносим извинения за доставленные неудобства!</div>
<div className={styles.modal__title}>Принимаем заявки в телефонном режиме</div> 
<div className={styles.modal__title}>+7(928)226-53-81</div>
        </Modal> */}
        <div className={styles.users} onClick={() => setUsers(true)}>Пользовательское соглашение</div>
        <div className={styles.agreement} onClick={() => setOpen(true)}>Политика конфиденциальности</div>
        <Agreement open={open} close={() => setOpen()} />
        <UsersAgree open={users} close={() => setUsers()} />
        </div>
    )
}

const mapDispatchToProps = {
    setSchool
};

export default withRouter(connect(null, mapDispatchToProps)(Main));