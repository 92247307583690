import React from "react";
import Modal from 'react-modal';
import {connect}  from 'react-redux';
import InputMask from 'react-input-mask';
import SVG from 'react-inlinesvg';

import styles from "./index.module.scss";

import close from '../../../../resources/images/close.svg';
import visa from '../../../../resources/images/visa.jpg';

function ModalContact(props) {

    return (
        <Modal  isOpen={props.modal}
                overlayClassName={styles.overlay}
                className={styles.modal}
                appElement={document.getElementById('root')}
                onRequestClose={() => props.close()}
                >
                    <SVG className={styles.close} src={close} alt="" onClick={() => props.close()}/>
                    <div className={styles.title}>Ваши контакты</div>
                    <div className={styles.wrap}>
                        <div className={styles.wrap__title}>Почта</div>
                        <input type="email" 
                               value={props.mail} 
                               className={styles.wrap__input}
                               onChange={(e) => props.setMail(e.target.value)} />
                    </div>
                    <div className={styles.wrap}>
                        <div className={styles.wrap__title}>Телефон</div>
                        <InputMask type="text" 
                               value={props.phone} 
                               className={styles.wrap__input}
                               onChange={(e) => props.setPhone(e.target.value)}
                               name="phoneNumber"
                               mask="+7 (999) 999-99-99"
                               maskChar=""
                                />
                    </div>
                    <div className={styles.btn} onClick={() => props.pay()}>Оплатить</div>
                    <img src={visa} alt="" className={styles.visa}/>
        </Modal>
    )
}


const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(ModalContact);