import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { enableBatching } from 'redux-batched-actions';
import thunk from 'redux-thunk';

import rootReducer from './mainReducer';

const middleware = [thunk];

const store = createStore(
  enableBatching(rootReducer),
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
