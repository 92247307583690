import React from "react";
import Modal from 'react-modal';
import {connect}  from 'react-redux';
import SVG from 'react-inlinesvg';
import {setDescription} from '../../Actions/ordersActions';

import close from '../../../../resources/images/close.svg';

import styles from "./index.module.scss";

function Description(props) {
    return (
        <Modal  isOpen={props.description}
                overlayClassName={styles.overlay}
                className={styles.modal}
                appElement={document.getElementById('root')}
                onRequestClose={() => props.setDescription(false)}
                >
         <SVG className={styles.close} src={close} alt="" onClick={() => props.setDescription(false)}/>
        <div className={styles.title}>Описание</div>
        <div className={styles.text}>Футболка универсальная подойдёт как мальчикам так и девочкам, свободный прямой крой, выполнена из трикотажа с добавлением лайкры, что позволяет футболке долго сохранять форму и хороший внешний вид даже после многочисленных стирок. Логотип выполнен при помощи Шелкотрафаретной печати на проффесиональном оборудовании что гарантирует безупречное качество нанесения.</div>
        <div className={styles.wrap}>
            <div className={styles.wrap__title}>Качество</div>
            <div className={styles.wrap__description}>Премиум 160 - 170 г/м</div>
            <div className={styles.wrap__title}>Состав</div>
            <div className={styles.wrap__description}>Хлопок 95% , лайкра 5%</div>
        </div>
        <div className={styles.title}>Как подобрать размер</div>
        <div className={styles.text}>Что бы правильно подобрать размер, возьмите свою футболку которую вы носите и измерьте её длину и ширину, затем сопоставте с данными в таблице которые максимально близко подходят к Вашим размерам.</div>
        <div className={styles.title}>Правила ухода</div>
        <div className={styles.text}>Изображение нанесенное методом Шелкографии достаточно стойкое к истиранию, но все же необходимо следовать рекомендациям:<br/>
В стиральной машине разрешается стирать при максимальной температуре 40 градусов по Цельсию, а скорость отжима не должна превышать 600 оборотов в минуту. <br/>
Всегда выворачивайте изделия наизнанку, чтобы принт не контактировал с металличискими частями барабана в стиральной машине.<br/>
Применяйте щадящие моющие средства, без отбеливателей.<br/>
Запрещается химическая чистка футболок с рисунком.<br/>
Гладить можно при невысокой температуре и только с изнаночной стороны.</div>
        
        </Modal>
    )
}

const mapStateToProps = state => ({
    description: state.ordersReducer.description
});


const mapDispatchToProps = {
    setDescription
};

export default connect(mapStateToProps, mapDispatchToProps)(Description);